import { Capacitor } from '@capacitor/core';
import { uuidv7 } from 'uuidv7';
import { Database } from './database';
import { SqliteKvStoreProvider } from './kv-store';

console.log('sqlite module loaded');
export class SqliteSystem {
  static isEnabled = Capacitor.getPlatform() === 'ios';

  static async initializeSingleton() {
    const { default: SqliteWorker } = await import('./sqlite-worker?worker');
    if (sqliteSystem) {
      return sqliteSystem;
    }
    sqliteSystem = new SqliteSystem(new SqliteWorker());

    return sqliteSystem;
  }

  private _kvStoreProvider: SqliteKvStoreProvider;

  constructor(private worker: Worker) {
    const clientId = uuidv7();
    const database = new Database(clientId, this.worker);
    this._kvStoreProvider = new SqliteKvStoreProvider(database);
  }

  get kvStoreProvider() {
    return this._kvStoreProvider;
  }
}

let sqliteSystem: SqliteSystem;
