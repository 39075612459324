import { CacheType, ObjectPool } from '@/models/object-pool';
import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import { ReadonlyJSONObject } from 'replicache';
import { ManagedCache } from './managed-cache';
import {
  createMembershipReplicache,
  createProjectReplicache,
  createUserReplicache,
  MembershipReplicache,
  ProjectReplicache,
  UserReplicache,
} from './replicache';
import { SqliteSystem } from './sqlite';

export class CacheStore {
  private _userCache: ManagedCache<UserReplicache> | null = null;
  private _membershipCache: ManagedCache<MembershipReplicache> | null = null;
  private _projectCache: ManagedCache<ProjectReplicache> | null = null;

  public objectPool: ObjectPool;

  static initializeSingleton(objectPool: ObjectPool) {
    cacheStore = new CacheStore(objectPool);
    return cacheStore;
  }

  constructor(objectPool: ObjectPool) {
    this.objectPool = objectPool;

    makeAutoObservable(this);
  }

  public get userCache() {
    return this._userCache;
  }

  public get membershipCache() {
    return this._membershipCache;
  }

  public get projectCache() {
    return this._projectCache;
  }

  public get finishedSyncing() {
    const caches = [this.userCache, this.membershipCache, this.projectCache];
    return caches.every((cache) => !cache || cache.finishedSyncing);
  }

  public get initialLoadFinished() {
    const caches = [this.userCache, this.membershipCache, this.projectCache];
    return caches.every((cache) => !cache || cache.initialLoadFinished);
  }

  public pullAll() {
    this.userCache?.pull();
    this.membershipCache?.pull();
    this.projectCache?.pull();
  }

  public setUserCache(userCache: ManagedCache<UserReplicache>) {
    this.userCache?.close();
    this._userCache = userCache;
    this.objectPool.removeCache('user');
    this.watchCache('user', userCache);
    userCache.initializeCache();
  }

  public async setUserId(userId: string, userToken: string) {
    if (this.userCache?.cacheId === 'user-' + userId) {
      return;
    }

    const replicache = createUserReplicache({
      userId,
      userToken,
      kvStore: await this.getKvStore(),
    });
    const managedCache = new ManagedCache('user-' + userId, replicache);
    this.setUserCache(managedCache);
  }

  public async setOrganizationId(
    userId: string,
    organizationId: string,
    membershipToken: string
  ) {
    if (this.membershipCache?.cacheId === 'membership-' + organizationId) {
      return;
    }

    const replicache = createMembershipReplicache({
      userId,
      organizationId,
      token: membershipToken,
      kvStore: await this.getKvStore(),
    });
    const managedCache = new ManagedCache(
      'membership-' + organizationId,
      replicache
    );
    this.setMembershipCache(managedCache);
  }

  public async setProjectId(
    userId: string,
    organizationId: string,
    projectId: string,
    projectToken: string
  ) {
    if (this.projectCache?.cacheId === 'project-' + projectId) {
      return;
    }

    const replicache = createProjectReplicache({
      userId,
      organizationId,
      projectId,
      token: projectToken,
      kvStore: await this.getKvStore(),
    });
    const managedCache = new ManagedCache('project-' + projectId, replicache);
    this.setProjectCache(managedCache);
  }

  public setMembershipCache(
    membershipCache: ManagedCache<MembershipReplicache>
  ) {
    this.membershipCache?.close();
    this._membershipCache = membershipCache;
    this.objectPool.removeCache('membership');
    this.watchCache('membership', membershipCache);
    membershipCache.initializeCache();
  }

  public setProjectCache(projectCache: ManagedCache<ProjectReplicache>) {
    this.projectCache?.close();
    this._projectCache = projectCache;
    this.objectPool.removeCache('project');
    this.watchCache('project', projectCache);
    projectCache.initializeCache();
  }

  private watchCache(cacheType: CacheType, managedCache: ManagedCache) {
    managedCache.startWatch({
      onRecordAdded: (key, value) => {
        this.objectPool.addObject(cacheType, key, value as ReadonlyJSONObject);
      },
      onRecordRemoved: (key) => {
        this.objectPool.removeObject(key);
      },
      onRecordUpdated: (key, oldValue, newValue) => {
        this.objectPool.updateObject(key, newValue as ReadonlyJSONObject);
      },
    });
  }

  private async getKvStore() {
    if (SqliteSystem.isEnabled) {
      const sqliteSystem = await SqliteSystem.initializeSingleton();
      return sqliteSystem.kvStoreProvider;
    }
    return 'idb';
  }
}

export let cacheStore: CacheStore;

export const CacheStoreContext = createContext<CacheStore | null>(null);

export const useCacheStore = () => {
  const cacheStore = useContext(CacheStoreContext);
  if (!cacheStore) {
    throw new Error('useCacheStore must be used within a CacheStoreProvider');
  }
  return cacheStore;
};
