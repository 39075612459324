import { resolver, type Resolver } from '../resolver';

interface Sendable {
  messageId: string;
}

export class MessageHighway<
  InputMessage extends Sendable,
  OutputMessage extends Sendable,
> {
  outbound: Record<string, Resolver<OutputMessage>> = {};

  constructor(
    private port: Worker | MessagePort,
    private timeout = 2000
  ) {
    port.onmessage = (message) => this.onMessage(message);
  }

  async postMessage(message: InputMessage) {
    const messageId = message.messageId;
    this.outbound[messageId] = resolver();

    this.port.postMessage(message);

    const timeout = setTimeout(() => {
      console.warn('Message timed out', message);
    }, this.timeout);

    const data = await this.outbound[messageId].promise;
    clearTimeout(timeout);
    return data;
  }

  private onMessage(message: MessageEvent) {
    const { messageId } = message.data;
    const resolver = this.outbound[messageId];
    if (!resolver) {
      console.warn('No resolver for message', messageId);
      return;
    } else {
      resolver.resolve(message.data);
      delete this.outbound[messageId];
    }
  }
}
